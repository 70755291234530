import React from "react";
import clsx from "clsx";
import { QQLink, QQSection } from "common/components";
import { QuoteResultsDownloadUri } from "./constants";
import { trackCreateQuote, trackDownloadQuote } from "common/tagManagerService";
import { useEgerdauToken } from "common/services";

interface SummaryLinkSectionProps {
  uid: string;
}

const downloadFileWithToken = async (uid: string, token: string) => {
  try {
    const response = await fetch(`${QuoteResultsDownloadUri(uid)}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const disposition = response.headers.get("Content-Disposition");
    if (disposition && disposition.includes("attachment")) {
      const filenameMatch = disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      if (filenameMatch && filenameMatch[1]) {
        link.download = filenameMatch[1].replace(/['"]/g, "");
      }
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const SummaryLinkSection: React.FC<SummaryLinkSectionProps> = ({ uid }) => {
  const token = useEgerdauToken();
  const handleDownloadClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    trackDownloadQuote();
    downloadFileWithToken(uid, token);
  };

  return (
    <QQSection blue className={clsx("d-flex justify-content-end align-items-center py-14 px-20")}>
      <span className="d-inline-block mr-32">
        <QQLink
          onClick={() => trackCreateQuote()}
          link
          href="/"
          className="font-size-14 font-weight-bold"
        >
          Create Another Quote
        </QQLink>
      </span>
      <span>
        <QQLink onClick={handleDownloadClick} button href="#" className="font-size-14">
          Download Quote
        </QQLink>
      </span>
    </QQSection>
  );
};
