import { appState, setAuxConfiguration, useAppDispatch, useAppSelector } from "app";
import { EgerdauCustomer, useGetSAPAuxilaryDataQuery } from "common/services";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useEffect } from "react";
import { useEgerdauToken } from "./useLoadEgerdauToken";

export const useLoadSAPData = (customer: EgerdauCustomer | null) => {
  const dispatch = useAppDispatch();
  const defaultId = "0100948867";
  const { sapAuxProfile } = useAppSelector(appState);
  const shipTo: string =
    customer?.shipTo && customer.shipTo?.length > 0
      ? customer.shipTo[0].id || defaultId
      : defaultId;
  const soldTo: string = customer?.soldToId || defaultId;

  const shouldFetchSAPAuxiliaryData = customer && !sapAuxProfile;

  const sapAuxiliaryDataParams = {
    shipTo,
    soldTo,
  };

  const token = useEgerdauToken();
  const { error, data } = useGetSAPAuxilaryDataQuery(
    shouldFetchSAPAuxiliaryData ? { token, args: sapAuxiliaryDataParams } : skipToken,
  );

  useEffect(() => {
    if (data) {
      dispatch(setAuxConfiguration({ ...data, shipTo, soldTo }));
    }
  }, [data, shipTo, soldTo, dispatch]);

  return {
    sapAuxProfile,
    error,
  };
};
