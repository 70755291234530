import { datadogLogs } from "@datadog/browser-logs";
import { ErrorMessage, getErrorMessage } from "./Errors";
import { getHeadereGerdauToken, geteGerdauAuthHeader } from "common/services";

const jsonResponse = async (response: Response) => await response.json();

interface QQTApiResponse {
  data: any;
  error: ErrorMessage | null;
}

const validateResponse = async (response: Promise<any>) => {
  const result: QQTApiResponse = { data: null, error: null };
  try {
    const { data, error } = await response;
    if (data) result.data = data;
    if (error) {
      datadogLogs.logger.error("[APIData]: Error", {
        name: "Error : Validate Response",
        details: error,
      });
      result.error = getErrorMessage(error);
    }
  } catch (error: any) {
    datadogLogs.logger.error("[APIData]: Error", {
      name: "Error in API Data: Validate Response",
      details: error,
    });
    result.error = getErrorMessage(error);
  }
  return result;
};

export const getData = async (url: string, token: string) => {
  let fetchData: Response | undefined = undefined;
  let result: QQTApiResponse = { data: null, error: null };
  try {
    fetchData = await fetch(url, {
      mode: "cors",
      headers: geteGerdauAuthHeader(token),
    });
  } catch (ex: any) {
    const errorDetails = getErrorMessage("");
    datadogLogs.logger.error("[APIData]: Error", {
      name: "Error in API Data: Invalid Response",
      details: errorDetails,
    });
    result = { data: null, error: errorDetails };
  }
  if (fetchData !== undefined) {
    result = await validateResponse(jsonResponse(fetchData));
  }
  return result;
};

export const getWithHeaders = async <T>(url: string, headers: HeadersInit, token?: string) => {
  const mergedHeaders = new Headers(headers);
  if (token) {
    mergedHeaders.set("Authorization", `Bearer ${token}`);
  }
  return (await jsonResponse(
    await fetch(url, {
      mode: "cors",
      headers: mergedHeaders,
    }),
  )) as T;
};

export const postData = async (url: string, token: string, body?: BodyInit) => {
  return await validateResponse(post(url, token, body));
};

export const post = async (url: string, token: string, body?: BodyInit, headers?: HeadersInit) => {
  const mergedHeaders = new Headers(headers);
  mergedHeaders.set("Authorization", getHeadereGerdauToken(token));
  return await jsonResponse(
    await fetch(url, {
      method: "POST",
      mode: "cors",
      body,
      headers: mergedHeaders,
    }),
  );
};

export const postNoResponse = async (input: RequestInfo, token: string, body?: BodyInit) => {
  let error: string | null = null;
  try {
    const data: { error: string } | null = await jsonResponse(
      await fetch(input, {
        method: "POST",
        mode: "cors",
        body,
        headers: geteGerdauAuthHeader(token),
      }),
    );
    if (data && "error" in data) {
      datadogLogs.logger.error("[APIData]: Error in Post No response", {
        name: "Error : Post no Response",
        details: "Error: " + error + " Data " + data,
      });
      error = data.error;
    }
  } catch (ex: any) {
    datadogLogs.logger.error("[APIData]: Error", {
      name: "Error in API Data: Post no response",
      details: ex,
    });
    error = ex;
  }
  return error;
};
