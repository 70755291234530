import { IconColors, QQLink, QQSection, SvgIcon } from "common/components";
import { FileType, FileUpload } from "./types";
import React, { useState } from "react";
import { FileDropArea } from "./FileDropArea";
import { FileTypeForm } from "./FileTypeForm";
import clsx from "clsx";
import { TemplateDownloadUrl } from "../utils";
import styles from "./Upload.module.scss";
import { getEnv, useEgerdauToken } from "common/services";
import { ToggleButton } from "react-bootstrap";
import { datadogLogs } from "@datadog/browser-logs";
import { trackDownloadTemplate, trackUploadFileContinue } from "common/tagManagerService";

interface UploadProps {
  uploadFile: File | null;
  fileType: FileType | null;
  onSubmit?: (fileUpload: FileUpload) => void;
}

const downloadTemplateWithToken = async (token: string) => {
  try {
    const uri = TemplateDownloadUrl;
    const response = await fetch(uri, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to download template");
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const disposition = response.headers.get("Content-Disposition");
    if (disposition && disposition.includes("attachment")) {
      const filenameMatch = disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      if (filenameMatch && filenameMatch[1]) {
        link.download = filenameMatch[1].replace(/['"]/g, "");
      }
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading template:", error);
  }
};

export const Upload: React.FC<UploadProps> = ({ uploadFile, fileType, onSubmit = () => {} }) => {
  const [file, setFile] = useState<File | null>(uploadFile);
  const handleOnSubmit = (fileType: FileType) => {
    if (file) {
      trackUploadFileContinue(file.name, fileType);
      onSubmit({ file, fileType });
    }
  };
  const [showMaintenanceView, setShowMaintenanceView] = useState(false);
  const [showNewTemplateMessage] = useState(true);
  const token = useEgerdauToken();
  const handleDownloadClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    trackDownloadTemplate();
    downloadTemplateWithToken(token);
  };

  const toggle = () => {
    if (getEnv() === "DEV" || getEnv() === "LOCAL" || getEnv() === "QA") {
      return (
        <div className={clsx(styles.templateDownloadBtnArea, "mt-30 p-20 text-center")}>
          <ToggleButton
            type="checkbox"
            id="tbg-btn-1"
            value={1}
            checked={showMaintenanceView}
            onChange={(e) => setShowMaintenanceView(e.currentTarget.checked)}
            className="d-inline-flex align-items-center text-white font-size-14"
          >
            Maintenance View
          </ToggleButton>
        </div>
      );
    }
  };

  const maintenanceMessage = () => {
    if (showMaintenanceView) {
      datadogLogs.logger.warn("[Upload] Maintenance Message View", {
        name: "Maintenance Message Viewable",
        details: "Maintenance Message Viewable",
      });

      return (
        <QQSection bottomSection>
          <div className={clsx(styles.maintenanceArea, "mx-auto d-flex flex-column")}>
            <div className={clsx(styles.templateDownloadBtnArea, "mt-30 p-20 text-center")}>
              <SvgIcon
                name="firstAid"
                attributes={{
                  width: "54px",
                  height: "49px",
                  fill: IconColors.GERDAUBLUE,
                }}
                className="mr-5"
              />
              <p className={clsx(styles.maintenanceTextTop, "mt-10 p-10")}>
                The site is down for Maintenance
              </p>
              <p className={clsx(styles.maintenanceTextBottom)}>We'll be up and running soon</p>
            </div>
            <QQLink
              onClick={handleDownloadClick}
              button
              href="#"
              className="align-items-center text-blue font-size-14 text-center mt-30 p-20 ml-60"
            >
              Download our{" "}
              <SvgIcon
                name="xlsx-document"
                attributes={{
                  width: "12px",
                  height: "10px",
                  fill: IconColors.GERDAUBLUE,
                }}
                className="mr-5"
              />{" "}
              Template
            </QQLink>
          </div>
        </QQSection>
      );
    }
  };

  const uploadView = () => {
    if (!showMaintenanceView && !showNewTemplateMessage) {
      return (
        <QQSection bottomSection>
          <div className={clsx(styles.uploadArea, "mx-auto d-flex flex-column")}>
            <div className={clsx(styles.templateDownloadBtnArea, "mt-30 p-20 bg-info text-center")}>
              <QQLink
                onClick={handleDownloadClick}
                button
                href="#"
                className="d-inline-flex align-items-center text-white font-size-14"
              >
                <SvgIcon
                  name="download-white"
                  attributes={{
                    width: "12px",
                    height: "10px",
                    fill: IconColors.WHITE,
                  }}
                  className="mr-5"
                />
                Download Excel Template
              </QQLink>
              <p className={clsx(styles.howToLinkText, "mt-5")}>
                See the{" "}
                <a href="/how-to#format " className="d-inline-block text-decoration-none">
                  How To
                </a>{" "}
                for help formatting your file.
              </p>
            </div>
            <FileDropArea onFileSelected={(file) => setFile(file)} />
            <p className="m-0 mt-42 pb-52 mx-auto text-dark font-size-14 font-weight-600">
              {file?.name ? (
                <span className="d-inline-flex align-items-end font-weight-bold">
                  <SvgIcon
                    name="xlsx-document"
                    attributes={{
                      width: "13px",
                      height: "16px",
                      fill: IconColors.NAVYBLUE,
                      viewBox: "0 0 20 21",
                    }}
                    className="mr-2"
                  />
                  <span className={styles.lh1}>{file.name}</span>
                </span>
              ) : (
                "No File Selected"
              )}
            </p>
          </div>
          {file && (
            <QQSection bottomSection>
              <FileTypeForm type={fileType} onSubmit={handleOnSubmit} />
            </QQSection>
          )}
        </QQSection>
      );
    }
  };

  const newTemplateView = () => {
    if (!showMaintenanceView && showNewTemplateMessage) {
      return (
        <QQSection bottomSection>
          <div className={clsx(styles.uploadArea, "mx-auto d-flex flex-column")}>
            <div
              className={clsx(styles.templateDownloadBtnArea, "mt-30 p-20 bg-success text-center")}
            >
              <p
                className={clsx(
                  styles.templateUpdateHeader,
                  "font-size-12 font-weight-800 font-weight-bold",
                )}
              >
                New version required
              </p>
              <p className={clsx(styles.templateUpdateMessage, "font-size-12 font-weight-400")}>
                Includes Mill Mark to help track your components.
              </p>
              <QQLink
                onClick={handleDownloadClick}
                button
                href="#"
                className="d-inline-flex align-items-center text-white font-size-14"
              >
                <SvgIcon
                  name="download-white"
                  attributes={{
                    width: "12px",
                    height: "10px",
                    fill: IconColors.WHITE,
                  }}
                  className="mr-5"
                />
                Download Excel Template
              </QQLink>
              <p className={clsx(styles.howToLinkText, "mt-5")}>
                See the{" "}
                <a href="/how-to#format " className="d-inline-block text-decoration-none">
                  How To
                </a>{" "}
                for help formatting your file.
              </p>
            </div>
            <FileDropArea onFileSelected={(file) => setFile(file)} />
            <p className="m-0 mt-42 pb-52 mx-auto text-dark font-size-14 font-weight-600">
              {file?.name ? (
                <span className="d-inline-flex align-items-end font-weight-bold">
                  <SvgIcon
                    name="xlsx-document"
                    attributes={{
                      width: "13px",
                      height: "16px",
                      fill: IconColors.GERDAUBLUE,
                      viewBox: "0 0 20 21",
                    }}
                    className="mr-2"
                  />
                  <span className={styles.lh1}>{file.name}</span>
                </span>
              ) : (
                "No File Selected"
              )}
            </p>
          </div>
          {file && (
            <QQSection bottomSection>
              <FileTypeForm type={fileType} onSubmit={handleOnSubmit} />
            </QQSection>
          )}
        </QQSection>
      );
    }
  };

  return (
    <>
      {toggle()}
      {maintenanceMessage()}
      {uploadView()}
      {newTemplateView()}
    </>
  );
};
