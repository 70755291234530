import { datadogLogs } from "@datadog/browser-logs";
import { Notification, NotifyType } from "common/components";
import { ContactSalesRep } from "features/Quote/utils";
import React, { useEffect, useState } from "react";
import { formatItemDescription, getParsedItems, ParsedItem } from "../utils";
import { useEgerdauToken } from "common/services";

interface ItemNoQuoteSectionProps {
  uid: string;
}
export const NotQuotedSection: React.FunctionComponent<ItemNoQuoteSectionProps> = ({ uid }) => {
  const token = useEgerdauToken();
  const [itemsNotQuoted, setItemsNotQuoted] = useState<ParsedItem[]>([]);
  useEffect(() => {
    const getItems = async () => {
      const result = await getParsedItems(uid, token);
      if (result.data) {
        const data = result.data as ParsedItem[];
        setItemsNotQuoted(data.filter((item) => item.item_exists === ContactSalesRep));
      } else {
        datadogLogs.logger.error("[Not Quoted Section]: Error set items not quoted", {
          name: "Error :set items not quoted",
          details: result.error,
        });
        console.error(result.error);
      }
    };
    getItems();
  }, [uid, token]);
  return (
    <div className="p-28 text-dark">
      <p className="font-size-24 font-weight-bold m-0 mb-8">
        <Notification
          type={NotifyType.Error}
          text="Unable to automatically quote the following items"
          width="16px"
          height="16px"
          classname="mr-18"
        />
        <span className="font-size-16 ml-3 font-weight-normal">({itemsNotQuoted.length})</span>
      </p>
      <ul className="list-unstyled ml-32 font-size-12">
        {itemsNotQuoted.map((item) => (
          <li key={item.uid}>
            {formatItemDescription(item)}
            {` - ${item.pieces} PC Requested`}
          </li>
        ))}
      </ul>
    </div>
  );
};
