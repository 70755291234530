import { appState, setAccessToken, useAppDispatch, useAppSelector } from "app";
import {
  CodeParamKey,
  setLocalData,
  setTokenExpirationDate,
  TokenKey,
  useGetEgerdauTokenMutation,
} from "common/services";
import { useEffect, useState } from "react";

const getFromQuery = () => new URLSearchParams(window.location.search);
const getAuthCode = (key: string) => getFromQuery().get(key);

export const useLoadEgerdauToken = () => {
  const dispatch = useAppDispatch();
  // initial app state will attempt to load cached non expired token otherwise null
  const { accessToken: cachedAccessToken } = useAppSelector(appState);
  // no token, lets see if we were redirected with an auth code
  const authCode = cachedAccessToken ? null : getAuthCode(CodeParamKey);
  const [getRemoteToken, { error, data }] = useGetEgerdauTokenMutation();

  let [remoteTokenError, setRemoteTokenError] = useState<any>(null);
  useEffect(() => {
    const fetchToken = async () => {
      if (authCode) {
        try {
          await getRemoteToken(authCode);
        } catch (error) {
          setRemoteTokenError(error);
        }
      }
    };
    fetchToken();
  }, [authCode, getRemoteToken]);

  useEffect(() => {
    if (data) {
      setLocalData(TokenKey, setTokenExpirationDate(data));
      dispatch(setAccessToken(data.access_token));
    }
  }, [data, dispatch]);

  return {
    data,
    error,
    remoteTokenError,
    authCode,
    cachedAccessToken,
    isRedirect: (!cachedAccessToken && !authCode) || Boolean(error),
  };
};

export const useEgerdauToken = () => {
  const { accessToken } = useAppSelector(appState);
  return accessToken || "";
};

export const getHeadereGerdauToken = (token: string) => {
  return `Bearer ${token}`;
};

export const geteGerdauAuthHeader = (token: string) => {
  return { Authorization: getHeadereGerdauToken(token) };
};
