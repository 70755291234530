import React from "react";
import { QuoteDownloadUrl } from "./constants";
import { QuoteUploadData } from "./types";
import { pluralize, useEgerdauToken } from "common/services";
import { QQSection, QQLink, SvgIcon } from "common/components";

interface LookupDetailProps {
  quote?: QuoteUploadData[];
  resultsSummaryText: () => React.ReactNode;
}

const downloadFileWithToken = async (quoteUid: string, token: string) => {
  try {
    const uri = `${QuoteDownloadUrl}/${quoteUid}`;
    const response = await fetch(uri, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const disposition = response.headers.get("Content-Disposition");
    if (disposition && disposition.includes("attachment")) {
      const filenameMatch = disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      if (filenameMatch && filenameMatch[1]) {
        link.download = filenameMatch[1].replace(/['"]/g, "");
      }
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const LookupDetail: React.FC<LookupDetailProps> = ({
  quote,
  resultsSummaryText = () => null,
}) => {
  const token = useEgerdauToken();
  const handleDownloadClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    quoteUid: string,
  ) => {
    event.preventDefault();
    downloadFileWithToken(quoteUid, token);
  };

  return quote ? (
    <QQSection className="px-42 py-28">
      <p className="font-size-16 m-0 p-0 mb-14">{resultsSummaryText()}</p>
      <table className="table table-bordered font-size-12 text-light">
        <thead>
          <tr className="bg-secondary text-white">
            <th>QQT ID</th>
            <th>Sold To No.</th>
            <th>Sold To Name</th>
            <th>Customer Name</th>
            <th>Customer Email</th>
            <th>File Name</th>
            <th colSpan={2}>Creation Date</th>
          </tr>
        </thead>
        <tbody>
          {quote.map((quote) => (
            <tr key={quote.quoteUid}>
              <td>{quote.quoteId}</td>
              <td>{quote.soldToId}</td>
              <td>{quote.soldToName}</td>
              <td>{quote.userName}</td>
              <td>{quote.userId}</td>
              <td>{quote.fileName}</td>
              <td>
                <div className="d-flex align-items-center justify-content-around">
                  <span>{quote.uploadDate}</span>
                  <span>{`Aged ${pluralize(quote.daysAged, "day")}`}</span>
                </div>
              </td>
              <td>
                <span>
                  <QQLink
                    link
                    className="d-inline-flex align-items-center"
                    href="#"
                    onClick={(event) => handleDownloadClick(event, quote.quoteUid)}
                  >
                    <SvgIcon
                      name="download"
                      className="mr-3"
                      attributes={{ width: "8px", height: "8px" }}
                    />
                    <span>Download</span>
                  </QQLink>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </QQSection>
  ) : null;
};
