import {
  getData,
  getErrorMessage,
  getUrlWithQuery,
  getWithHeaders,
  postData,
  postNoResponse,
} from "common/services";
import { NestingPrefs, QuoteDetails, SelectedItem, ShipToFormValues } from "../QuoteResults/utils";
import {
  ItemOptionsUri,
  itemsEntity,
  MuleNestingApiUri,
  NestedQuoteUri,
  QuoteSelectionsUri,
  RequestDateUri,
  MuleResponse,
  NestedData,
  UnnestedFile,
} from ".";
import { normalize } from "normalizr";
import { datadogLogs } from "@datadog/browser-logs";
import { getTransportLabel } from "./transportOptions";

export const sendQuoteSelections = async (
  uid: string,
  token: string,
  selections: SelectedItem[],
) => {
  const error = await postNoResponse(
    `${QuoteSelectionsUri(uid)}`,
    token,
    JSON.stringify(selections),
  );
  if (error !== null) {
    datadogLogs.logger.error("[API]: Error send quote selections", {
      name: "Error: Send quote selections",
      details: error,
    });
    return getErrorMessage(error);
  }
  return error;
};
export const processNestedResults = async (
  uid: string,
  nesting: UnnestedFile,
  shippingMethod: string,
  prefs: NestingPrefs,
  token: string,
) => {
  let error: string | null = null;
  if (
    prefs.maxNestedBeamLength !== undefined &&
    prefs.kerfWeight !== undefined &&
    prefs.gap !== undefined &&
    prefs.trimCut !== undefined &&
    nesting.uid !== null &&
    nesting.mulesoftToken !== null &&
    shippingMethod !== null
  ) {
    try {
      let results = await getWithHeaders<MuleResponse<NestedData>>(
        getUrlWithQuery(`${MuleNestingApiUri}`, {
          onlyStandardLength: prefs.useStandardLength.toString(),
          maxLengthTolerable: prefs.maxNestedBeamLength.toString(),
          kerf: prefs.kerfWeight.toString(),
          gap: prefs.gap?.toString(),
          endTrim: prefs.trimCut?.toString(),
          id: nesting.uid,
          sameSequenceOnBar: "false",
          shippingMethod: shippingMethod,
        }),
        { "access-token": nesting.mulesoftToken },
        token,
      );

      if ("error" in results.message.data) {
        datadogLogs.logger.error("[API]: Error getting from nested data from mulesoft", {
          name: "Error: Error getting from nested data from mulesoft",
          details: results.message.data,
        });
        error = results.message.data.error.description;
      }

      if (!error) {
        error = await postNoResponse(
          `${NestedQuoteUri(uid)}`,
          token,
          JSON.stringify(results.message.data),
        );
      }
    } catch (ex: any) {
      datadogLogs.logger.error("[API]: Error getting from nested data from mulesoft", {
        name: "Error: Error getting from nested data from mulesoft",
        details: ex,
      });
      error = ex;
    }
  }
  if (error !== null) return getErrorMessage(error);
  return error;
};

const sendQuotePreferences = async (uid: string, token: string, values: ShipToFormValues) => {
  const payload = new FormData();
  payload.set("uid", uid);
  payload.set("ship_to", values.shipTo);
  const transportationLabel = getTransportLabel(values.transportation);
  payload.set("transportation", transportationLabel);
  payload.set("request_date", values.requestDate);
  payload.set("city_id", values.cityId);
  const url = `${RequestDateUri}`;
  return await postData(url, token, payload);
};

const getItemOptions = async (uid: string, token: string) => {
  let { data, error } = await getData(`${ItemOptionsUri(uid)}`, token);
  if (data) {
    data = normalize(data, [itemsEntity]);
    return { data: data as QuoteDetails, error };
  }
  return { data: null, error };
};

export const sendQuoteRequest = async (uid: string, token: string, values: ShipToFormValues) => {
  let result = await sendQuotePreferences(uid, token, values);
  if (!result.error) {
    return await getItemOptions(uid, token);
  }
  return result;
};
