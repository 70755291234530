import clsx from "clsx";
import React, { useEffect, useReducer } from "react";
import { QuoteUploadLookupUrl } from "./constants";
import { getUrlWithQuery, pluralize, getData, useEgerdauToken } from "common/services";
import styles from "./QuoteLookup.module.scss";
import { LookupDetail } from "./LookupDetail";
import { LookupHeader } from "./LookupHeader";
import {
  AgedPresetDefault,
  lookupError,
  quoteLookupReducer,
  setQuoteDetails,
  setSearchValue,
  setSearchValues,
} from "./slice";
import { AnyAction } from "@reduxjs/toolkit";
import { AppDispatch, startLoading, stopLoading, useAppDispatch } from "app";
import { PageCard, SvgIcon } from "common/components";
import { datadogLogs } from "@datadog/browser-logs";

const PresetSearches = new Map<string, string>([
  ["Today", "0"],
  ["Past 7 Days", "7"],
  ["Past 30 Days", "30"],
  ["Past 60 Days", "60"],
  ["All", "all"],
]);

const getPayload = (agedPreset: string, searchValue: string) => {
  const days = PresetSearches.get(agedPreset);
  const param1 = days ? { days_aged: days } : undefined;
  const param2 = searchValue ? { id: searchValue } : undefined;
  return { ...param1, ...param2 };
};
const getQuotes = async (
  appDispatch: AppDispatch,
  quoteDispatch: React.Dispatch<AnyAction>,
  agedPreset: string,
  searchValue: string,
  token: string,
) => {
  try {
    datadogLogs.logger.info("[Quote Lookup] START Load Quote Detail", {
      name: "START Load Quote Detail",
    });
    appDispatch(startLoading({ message: "Loading Quote Detail" }));
    let quoteDetail = await getData(
      getUrlWithQuery(QuoteUploadLookupUrl, getPayload(agedPreset, searchValue)),
      token,
    );
    if (quoteDetail.data) quoteDispatch(setQuoteDetails(quoteDetail.data));
    if (quoteDetail.error) throw quoteDetail.error;
  } catch (error: any) {
    datadogLogs.logger.error("[Quote Lookup] ERROR Load Quote Detail failed", {
      name: "ERROR Load Quote Detail",
      details: error,
    });
    console.error(error);
    quoteDispatch(lookupError(error.message));
  } finally {
    datadogLogs.logger.info("[Quote Lookup] END Load Quote Detail", {
      name: "END Load Quote Detail",
    });
    appDispatch(stopLoading());
  }
};

export const QuoteLookup: React.FC = () => {
  const [state, quoteDispatch] = useReducer(quoteLookupReducer, {
    quoteDetails: undefined,
    hasError: false,
    errorMessage: undefined,
    agedPreset: AgedPresetDefault,
    searchValue: "",
  });
  const token = useEgerdauToken();
  const appDispatch = useAppDispatch();
  useEffect(() => {
    getQuotes(appDispatch, quoteDispatch, AgedPresetDefault, "", token);
  }, [appDispatch, quoteDispatch, token]);

  const onSearchClick = async (value: string) => {
    const searchValue = value.trim();
    await getQuotes(appDispatch, quoteDispatch, state.agedPreset, searchValue, token);
    quoteDispatch(setSearchValue(searchValue));
  };
  const onButtonGroupClick = async (
    index: number,
    agedPreset: string,
    currentSearchValue: string | undefined,
  ) => {
    const searchValue = currentSearchValue === undefined ? "" : currentSearchValue.trim();

    if (state.agedPreset !== agedPreset || state.searchValue !== searchValue) {
      await getQuotes(appDispatch, quoteDispatch, agedPreset, searchValue, token);
      quoteDispatch(setSearchValues({ agedPreset, searchValue }));
    }
  };

  const onCleared = () => {
    getQuotes(appDispatch, quoteDispatch, AgedPresetDefault, "", token);
    quoteDispatch(setSearchValues({ agedPreset: AgedPresetDefault, searchValue: "" }));
  };

  const resultsSummaryText = () => {
    let summary: JSX.Element;
    if (state.quoteDetails) {
      const resultsText = pluralize(state.quoteDetails.length, "result");
      const joinText = state.agedPreset !== "Today" ? "over the" : "for";
      if (state.agedPreset && state.searchValue) {
        summary = (
          <>
            {resultsText} for "<b>{state.searchValue}</b>" {joinText} "<b>{state.agedPreset}</b>"
          </>
        );
      } else {
        summary = (
          <>
            {resultsText} {joinText} "<b>{state.agedPreset}</b>"
          </>
        );
      }
      return summary;
    }
    return null;
  };

  return (
    <PageCard className={clsx(styles.container, "mx-auto")}>
      <h2 className="d-flex align-items-center m-0 p-16 text-uppercase font-weight-bold">
        <SvgIcon name="binoculars" className="mr-8" attributes={{ width: "19", height: "20" }} />
        <span className="text-primary font-size-26">Quick Quote Look Up</span>
      </h2>
      <LookupHeader
        hasError={state.hasError}
        errorMessage={state.errorMessage}
        searchBtnLabels={Array.from(PresetSearches.keys())}
        selectedBtnLabel={state.agedPreset}
        onSearchSubmit={onSearchClick}
        onButtonGroupClick={onButtonGroupClick}
        onCleared={onCleared}
      />
      <LookupDetail quote={state.quoteDetails} resultsSummaryText={resultsSummaryText} />
    </PageCard>
  );
};
